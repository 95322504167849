@font-face {
  font-family: 'Batuphat Script';
  src: url('BatuphatScript-Regular.woff2') format('woff2'),
  url('BatuphatScript-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fredoka One';
  src: url('FredokaOne-Regular.woff2') format('woff2'),
  url('FredokaOne-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fredoka';
  src: url('Fredoka-Regular.woff2') format('woff2'),
  url('Fredoka-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
