@use '@angular/material' as mat;
@import '@angular/material/theming';

@include mat-core();
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: white;
$dark-primary-bg: rgba(black, 0.87);
$dark-blue: #3282B8;
$dark-orange: #ff9a40;
$dark-secondary-bg: white;
$light-primary-text: white;

@mixin checkbox-theme($color) {
  .mat-checkbox-background {
    background-color: $color !important;
    border-color: $color !important;
  }
}

@mixin radio-theme($color) {
  .mat-radio-outer-circle {
    border-color: $color !important;
  }
  .mat-radio-inner-circle {
    background-color: $color !important;
  }
}

.mat-checkbox-checked {
  @include checkbox-theme($dark-blue);
}

.mat-checkbox-disabled.mat-checkbox-checked {
  @include checkbox-theme(gray);
}

.mat-radio-disabled.mat-radio-checked {
  @include radio-theme(gray);
}

.mat-radio-button.mat-accent.mat-radio-checked {
  @include radio-theme($dark-blue);
  background-color: white !important;
}

.message-warning {
  color: $dark-orange;
}
.mat-badge-accent .mat-badge-content {
  background: $dark-orange;
}

.mat-checkbox-ripple {
  display: none;
}

.mat-checkbox {
  label {
    color: #FFF7FA !important;
  }
  color: #FFF7FA !important;
}

.mat-checkbox-frame {
  border-color: white !important;
}

.mat-checkbox-checkmark {
  display: none;
}

.white-btn {
  background: white !important;
  color: black !important;
}

.float-right {
  float: right;
}

.margin-left-15px {
  margin-left: 15px !important;
}

.gap-15 {
  gap: 15px;
}

.flex {
  display: flex;
}

.flex-collum {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.table-dark-theme {
  background-color: black;
  color: white;

  mat-cell {
    color: white !important;
  }


  mat-row, tr.mat-row, .mat-row {
    border-bottom-color: white !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .mat-header-cell, th.mat-header-cell, td.mat-cell, .mat-row, .mat-paginator, .mat-header-row {
    font-size: 16px !important;
    background-color: black !important;
    color: white !important;

  }

  .mat-form-field-infix {
    border-bottom-color: white !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .mat-header-cell {
    border-bottom-color: white !important;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    color: white !important;
  }

  .mat-paginator {
    .mat-select-value-text {
      color: white !important;
    }

    border-top-color: white !important;
    color: white !important;
    border-top-width: 1px;
    border-top-style: solid;
  }

  .mat-form-field-label {
    color: white !important;
  }
}

.mat-form-field {
  //  border-style: solid;
}

///////////////////////


@mixin darken() {
  --pmTxtColor: #{$dark-primary-text};
  --scTxtColor: #{$dark-secondary-text};
  --pmBgColor: #{$dark-primary-bg};
  --scBgColor: #{$dark-secondary-bg};
  --blue: #{$dark-blue};
  --orange: #{$dark-orange};

}

body {
  @include darken();


}

.pre-line {
  white-space: pre-line;
}

.justify {
  text-align: justify;

}

body.dark {
  @include darken();
}

$pink-palette: (
  A100: #3282B8 !important,
  A200: #3282B8 !important,
  A400: #3282B8 !important,
  A700: #3282B8 !important,
  contrast: (
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$my-primary: mat.define-palette($pink-palette, A200, A100, A400);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
    theme: $my-primary
  ),
  typography: mat.define-typography-config(),
  density: 0,
));
@include mat.core-theme($my-theme);

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($my-theme);
@include mat.card-theme($my-theme);
@include mat.form-field-theme($my-theme);
@include mat.select-theme($my-theme);
/* You can add global styles to this file, and also import other style files */
@import "assets/animate.css";
@import "/node_modules/placeholder-loading/src/scss/placeholder-loading";
@import 'assets/fonts/fonts.scss';

.mat-tab-label {
  color: #ffffff !important;
  opacity: 1 !important;
}

html, body {
  height: 100%;
}

.max-width {
  width: 100%;
}

.display-none {
  display: none;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}

.animated-placeholder {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

.presentation {

  margin-top: 50px;
  margin-inline: 50px;

  p {
    line-height: 32px;
    font-size: 18px;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $dark-blue !important;
}

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: $dark-blue !important;

}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  .mat-radio-outer-circle {
    border-color: $dark-blue !important;
  }

  background-color: white !important;
}

.mat-radio-button.mat-accent.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: $dark-blue !important;
  }

  .mat-radio-inner-circle {
    background-color: $dark-blue !important;
  }

  background-color: white !important;
}

.mat-radio-disabled.mat-radio-button.mat-accent.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: gray !important;
  }

  .mat-radio-inner-circle {
    background-color: gray !important;
  }

  background-color: white !important;
}

mat-radio-button {
  .mat-ripple-element {
    background-color: $dark-blue !important;
  }
}

.mat-flat-button.mat-primary.mat-button-disabled, .mat-button-disabled {
  color: #8d8d8d !important;
  background-color: rgb(151 151 151 / 65%) !important;
}


.mat-form-field-infix .mat-input-element {
  caret-color: white !important;
}

.dashboard-size {

  max-width: 1700px;
  min-width: 1200px;
  margin-inline: 20px;
}

.mat-select-value {
  color: white !important;
}

.mat-select-arrow {
  color: white !important;
}

.mat-form-field-label {
  color: white !important;
}

.black-theme {
 .mat-form-field .mat-input-element {
    color: #ffffff !important;
  }

.mat-form-field .mat-form-field-label {
    color: #ffffff !important;
  }

 .mat-form-field .mat-form-field-underline {
    background-color: rgba(112, 128, 144, 0);
  }

 .mat-form-field .mat-form-field-ripple {
    background-color: #ffffff;
  }
  .mat-form-field .mat-form-field-required-marker {
    color: #ffffff  !important;
  }

.mat-form-field-flex {
    // border: 1px solid white !important;
  }

.mat-form-field.mat-focused .mat-form-field-label {
    color: #ffffff  !important;
  }

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: rgba(255, 0, 0, 0);
  }

 .mat-form-field.mat-focused .mat-form-field-required-marker {
    color: rgba(255, 77, 77, 0);
  }

 .mat-form-field.mat-focused .mat-input-element {
    color: #ffffff !important;
  }

 .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
  }

   .mat-select-value {
    color: white;
  }

   .mat-select-arrow {
    color: white;
  }

  .positronx {
    text-decoration: none;
    color: #ffffff !important;
  }

   .mat-form-field .mat-input-element {
    color: #ffffff !important;
  }

   .mat-form-field .mat-form-field-label {
    color: #ffffff !important;
  }

   .mat-form-field .mat-form-field-underline {
    background-color: rgba(112, 128, 144, 0);
  }

   .mat-form-field .mat-form-field-ripple {
    background-color: #ffffff;
  }

   .mat-form-field .mat-form-field-required-marker {
    color: #ffffff;
  }

   .mat-form-field-flex {
    // border: 1px solid white !important;
  }

   .mat-form-field.mat-focused .mat-form-field-label {
    color: #ffffff;
  }

   .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: rgba(255, 0, 0, 0);
  }

   .mat-form-field.mat-focused .mat-form-field-required-marker {
    color: rgba(255, 77, 77, 0);
  }

   .mat-form-field.mat-focused .mat-input-element {
    color: #ffffff;
  }

   .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}
.white-theme {
  .mat-select-value {
    color: black !important;
  }

  .mat-select-arrow {
    color: black !important;
  }

  .mat-form-field-label {
    color: black !important;
  }
  .mat-checkbox-frame {
    border-color: black !important;
  }

  color: black !important;

  .mat-checkbox label {
    color: black !important;
  }

  .mat-form-field-required-marker {
    color: black !important;

  }


  .mat-form-field-invalid {
    .mat-form-field-required-marker {
      color: #f44336 !important;
    }
  }

  .mat-form-field-infix {
    border-bottom-color: black !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  mat-label {
    color: black !important;
  }

  label {
    color: black !important;
  }

  .mat-form-field-infix .mat-input-element {
    color: black !important;
    caret-color: black !important;
  }

  .clickable {
    color: var(--blue) !important; /* Assurez-vous que cette couleur est prioritaire */
  }
}
.full-width{
  width: 100%;
}
body {

  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  min-height: 100%;
  height: auto;
  position: relative;
  display: block;
  color: #ffffff;
}


body {
  background: #000000;

  /* background: linear-gradient(120deg, #3b3b3b, #003366, #2f2f2f); /* Départ avec un gradient
  // background-size: 200% 200%; /* Taille du gradient pour l'animation
   // animation: BackgroundGradient 20s ease infinite; /* Animation du gradient
   background-attachment: fixed;
   position: relative;
   height: auto;

  /* &::before {
     content: '';
     position: fixed;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     background-image: url("assets/images/backGround2.webp");
     background-size: cover;
     background-position: center;
     opacity: 0.4;
     animation: OpacityChange 5s ease-in-out infinite; Animation d'opacité
     filter: blur(60px);
     z-index: -1;
   }

   @keyframes BackgroundGradient {
     0% {
       background-position: 0% 50%;
     }
     50% {
       background-position: 100% 50%;
     }
     100% {
       background-position: 0% 50%;
     }
   }

   /* Animation pour le changement d'opacité de l'image
   @keyframes OpacityChange {
     0%, 100% {
       opacity: 0.2;
     }
     50% {
       opacity: 0.4;
     }
   } */

}

.dev {
  background-color: #3b9f3f !important;
}


.copy {
  background-color: #ffe690;
  width: fit-content;
  border-radius: 4px;
}

primary {
  background-color: #3282B8 !important
}

.no-style-a {
  color: white !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
}

.main-wrapper {
  margin-top: 50px;
  text-align: center;
  display: flow;
  justify-content: normal;
  align-items: center;
}

.ip-center {
  text-align: center;
  display: flow;
  justify-content: normal;
  align-items: center;
}

.ip-content {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}

.ip-max-width {
  width: 100%;
  max-width: 640px;

}

.min-width-field {
  min-width: 250px
}

mat-divider.horizontal {
  width: 70%;
  height: 10px;
  border-top-color: black !important;
  margin: 0 auto;

}
.z-index-1{
  z-index: 1;
}

.ip-area {
  border-radius: 20px;
  background-color: white;
  color: black;
  padding: 30px;
}

.wrapper-popup {
  text-align: center;
}

@media screen and (max-width: 780px) {

  .presentation {
    margin-top: 20px;
    margin-inline: 10px;
  }
  h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 700px) {
  .ip-content {
    max-width: 90vw;
  }
  .main-wrapper {
    text-align: -webkit-center;
  }
  .ip-area {
    min-width: 320px;
    width: fit-content;
    border-radius: 20px;
    background-color: white;
    color: black;
    padding: 20px;
  }
}

/* Scrollbar sous Firefox */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #FFFFFF #555;
  }
}

::-webkit-scrollbar {
  width: 6px; /* Largeur de la scrollbar */
  height: 6px; /* Largeur de la scrollbar */
  padding-left: 10px;

}

::-webkit-scrollbar-track {
  background: #555; /* Couleur de fond */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #f1f1f1; /* Couleur du curseur */
  border-radius: 10px; /* Arrondi des coins du curseur */
}

::-webkit-scrollbar-thumb:hover {
  background: #cccccc; /* Couleur du curseur au survol */
  border-radius: 10px;
}

.mat-tooltip {
  font-size: 0.8em;
}

.clickable {
  cursor: pointer;
  color: var(--blue) !important;
  font-weight: bold;
}
.left-auto{
  margin-left: auto !important;
}
